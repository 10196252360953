import { mapActions } from 'vuex';

// Custom Components
import CampaignInformation from '@/views/modules/campaigns/components/campaign-information/CampaignInformation.vue';
import CampaignSettings from '@/views/modules/campaigns/components/CampaignDeliveryType.vue';
import ContactListCreateOrEdit from '@/views/modules/contacts/ContactListCreateOrEdit.vue';
import PackageCheckoutModal from '@/views/modules/_components/PackageCheckoutModal.vue';

export default {
  components: {
    CampaignInformation,
    CampaignSettings,
    ContactListCreateOrEdit,
    PackageCheckoutModal,
  },
  data() {
    return {
      model: null,
      campaignInfoIsValid: false,
      campaignMessageIsValid: false,
      campaignSettingsIsValid: false,

      contactsAttributes: [],

      campaignBaseRoute: '',
      showCheckout: false,

      campaignSent: false,
      searchFiltersToFetchContacts: {
        nameOrPhoneInternational: {
          filterType: this.$enums.AppFilterType.CONDITIONAL,
          filtersMatch: this.$enums.AppFilterMathType.ANY,
          filter: {
            name: {
              filterType: this.$enums.AppFilterType.TEXT,
              type: this.$enums.AppFilter.FilterType.Text.Type.CONTAINS,
              filter: '',
            },
            phoneInternationalSignificant: {
              filterType: this.$enums.AppFilterType.TEXT,
              type: this.$enums.AppFilter.FilterType.Text.Type.CONTAINS,
              filter: '',
            },
            phoneInternationalSignificantComplete: {
              filterType: this.$enums.AppFilterType.TEXT,
              type: this.$enums.AppFilter.FilterType.Text.Type.CONTAINS,
              filter: '',
            },
          },
        },
      },
    };
  },
  computed: {
    verifyProperties() {
      return this.campaignInfoIsValid
        && this.campaignMessageIsValid
        && this.campaignSettingsIsValid
        && this.model
        && this.model.quickContacts.length > 0;
    },
    filterParamsToFetchContacts() {
      const filters = {
        phoneInternational: {
          filterType: this.$enums.AppFilterType.TEXT,
          type: this.$enums.AppFilter.FilterType.Text.Type.NOT_EQUAL,
          filter: '',
        },
      };

      let marketingStates = [
        this.$enums.Contact.MarketingStatus.SUBSCRIBED,
      ];

      marketingStates = this.model.messageType === this.$enums.Campaign.MessageType.TRANSACTIONAL
        ? [
          ...marketingStates,
          this.$enums.Contact.MarketingStatus.UNSUBSCRIBED,
        ]
        : marketingStates;

      filters.marketingStatus = {
        filterInner: 'value',
        filterType: this.$enums.AppFilterType.ENUM,
        type: this.$enums.AppFilter.FilterType.Enum.Type.ONE,
        filter: marketingStates,
      };

      return filters;
    },
  },
  watch: {
    'model.messageType': function (val) {
      this.$refs.vSelectServerPhoneNumber.resetSelectServer();

      if (val === this.$enums.Campaign.MessageType.MARKETING) {
        this.model.quickContacts = this.model.quickContacts.filter((c) => !c.optOut.value);
      }
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      fetchAllContacts: 'contact/fetchAllContacts',
      fetchAllAttributes: 'attribute/fetchAllAttributes',
    }),
    async fetchData() {
      await this.fetchContactsAttributes();
    },
    async fetchContactsAttributes() {
      const resp = await this.fetchAllAttributes({});
      this.contactsAttributes = resp.data;
    },
    confirmTestCampaign({ campaignType, senderId, cost }) {
      this.$showConfirmActionDialog({
        title: this.$t('$CampaignsModules.ConfirmTestCampaignTitle'),
        text: this.$t('$CampaignsModules.ConfirmTestCampaignMsg', {
          type: campaignType,
          senderId,
          cost: this.$options.filters.dollar(cost),
        }),
        accept: () => this.sendTestCampaign(senderId),
        acceptText: this.$t('$General.Send'),
      });
    },
    async sendTestCampaign(sender) {
      await this.testCampaign({
        ...this.model.toTestPayload(),
        sender,
      });

      this.$showSuccessActionNotification({
        title: this.$t('$CampaignsModules.TestCampaignSuccessNotifyTitle'),
        text: this.$t('$CampaignsModules.TestCampaignSuccessNotifyMsg'),
      });
    },
    async confirmSendQuickCampaign() {
      const costInfo = await this.fetchCostQuickCampaign(this.model.toCreatePayload());

      if (!costInfo.userHasSufficientBalance) {
        this.$showConfirmWarningDialog({
          title: this.$t('$CampaignsModules.InSufficientBalanceToSendCampaignNtf.Title'),
          text: this.$t('$CampaignsModules.InSufficientBalanceToSendCampaignNtf.Text'),
          acceptText: this.$t('$CampaignsModules.InSufficientBalanceToSendCampaignNtf.AcceptText'),
          cancelText: this.$t('$CampaignsModules.InSufficientBalanceToSendCampaignNtf.CancelText'),
          accept: () => {
            this.showCheckout = true;
          },
          cancel: () => false,
        });
      } else {
        this.$showConfirmActionDialog({
          title: this.$t('$QuickCampaignsModules.ConfirmSendCampaignTitle'),
          text: this.$t('$QuickCampaignsModules.ConfirmSendCampaignMsg', {
            contacts: costInfo.totalContactsToSend,
            entity: this.$tc(`$Entities.${this.entity}`),
            cost: this.$options.filters.dollar(costInfo.campaignTotalPrice),
          }),
          accept: this.saveQuickSMS,
          acceptText: this.$t('$General.Send'),
        });
      }
    },
    async saveQuickSMS() {
      if (this.model.deliveryType === this.$enums.Campaign.DeliveryType.IMMEDIATELY) {
        this.model.status = this.$enums.Campaign.Status.RUNNING;
      } else {
        this.model.status = this.$enums.Campaign.Status.PENDING;
      }

      await this.save(this.model.toSavePayload());
      this.campaignSent = true;

      if (this.campaignBaseRoute) {
        const path = this.model.status === this.$enums.Campaign.Status.PENDING
          ? 'scheduled' : 'history';
        await this.$router.push(`${this.campaignBaseRoute}/${path}`);
      }
    },
  },
};
